import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './ForgotPassword.css';
import { apiPost } from '../../services/apiService';
import API_ENDPOINTS from '../../config/api';
import { MdEmail } from "react-icons/md";
import logo from '../../assets/images/logo.png';

function ForgotPassword() {
    const [email, setEmail] = useState('');
    const navigate = useNavigate();

    const handleChange = (e) => {
        setEmail(e.target.value);
    };

    const handleForgotPassword = async (e) => {
        e.preventDefault();
        try {
            const response = await apiPost(API_ENDPOINTS.AUTH.FORGOT_PASSWORD, { email });
            console.log(response);
            navigate('/reset-link-sent'); // Navigate to a success screen or provide feedback
        } catch (error) {
            console.error('Failed to send reset link:', error);
        }
    };

    return (
        <div className={'main-container p-t-25'}>
            <form className="forgot-password-container">
                <img src={logo} alt="EliteCloud Finserve" className="logo" />
                <h2>Forgot Password</h2>
                <p className="forgot-password-subtitle">Enter your email to receive a reset link</p>

                <div className="input-group">
                    <MdEmail className="input-icon" />
                    <input
                        name="email"
                        value={email}
                        placeholder="Enter your email"
                        onChange={handleChange}
                        className="input-field"
                    />
                </div>

                <button className="forgot-password-button" onClick={handleForgotPassword}>
                    Send Reset Link
                </button>
            </form>
        </div>
    );
}

export default ForgotPassword;
