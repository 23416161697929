import React from 'react';
import { Navigate } from 'react-router-dom';

function PrivateRoute({ children }) {
    let token = localStorage.getItem('token');
    // token = "123456789456123";  //to byepass all pages now
    console.log(token);
    return token ? children : <Navigate to="/login" />;
}

export default PrivateRoute;