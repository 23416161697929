import React, { useState, useEffect } from 'react';
import './AboutUs.css';
import aboutImage1 from '../../assets/images/about1.png';
import aboutImage2 from '../../assets/images/about2.png';
import BottomNavigator from "../../components/bottomNavigator/BottomNavigator";

function AboutUs() {
    const [isMobile, setIsMobile] = useState(false);

    const handleResize = () => {
        const mobile = window.innerWidth <= 768;
        setIsMobile(mobile);
    };

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className={'main-container-fluid'}>
            <div className="about-us-container">
                <h1>Welcome to EliteCloud Finserve</h1>
                <p>
                    At EliteCloud Finserve, we bring you a premium platform for reliable investments and exciting rewards. Our app is designed for those who wish to make their money work for them, with opportunities that combine ease, efficiency, and profitability.
                </p>

                <img src={aboutImage1} alt="Staking Program" className="about-image" />

                <h2>About Our Staking Program</h2>
                <p>
                    Our innovative staking program allows users to earn a steady return of <strong>5% each month</strong> over a period of 6 months. Upon maturity, your principal amount and earnings are fully accessible, giving you the flexibility to reinvest or withdraw. It’s a straightforward, transparent way to grow your funds with peace of mind.
                </p>
                <p>
                    With a <strong>6-month maturity period</strong>, the program is ideal for those looking for medium-term investment plans that offer consistent returns without the complexities of traditional investment models.
                </p>

                <img src={aboutImage2} alt="Referral Program" className="about-image" />

                <h2>Referral Program</h2>
                <p>
                    We believe in rewarding our community. That's why we've introduced a comprehensive referral commission structure, allowing users to earn by inviting others to join EliteCloud Finserve. Our 10-level referral program is designed to maximize your earnings:
                </p>
                <table className="commission-table">
                    <thead>
                    <tr>
                        <th>Level</th>
                        <th>Commission Rate</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Level 1</td>
                        <td>5%</td>
                    </tr>
                    <tr>
                        <td>Level 2</td>
                        <td>2.5%</td>
                    </tr>
                    <tr>
                        <td>Level 3</td>
                        <td>1%</td>
                    </tr>
                    <tr>
                        <td>Level 4 - 10</td>
                        <td>0.5% each</td>
                    </tr>
                    </tbody>
                </table>

                <h2>Why Choose Us?</h2>
                <p>
                    At EliteCloud Finserve, we aim to make investing simple and accessible. Whether you're new to the world of finance or a seasoned investor, our app is designed to cater to your needs:
                </p>
                <ul>
                    <li><strong>Reliable Earnings:</strong> Steady monthly returns through our staking program.</li>
                    <li><strong>Simple Referral System:</strong> Earn as you expand your network.</li>
                    <li><strong>Secure Platform:</strong> Your investments are protected with the highest security standards.</li>
                    <li><strong>User-Friendly Interface:</strong> Seamless navigation and clear information, making it easy for anyone to get started.</li>
                </ul>


            </div>
            {isMobile && <BottomNavigator />}
        </div>
    );
}

export default AboutUs;
