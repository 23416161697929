import React from 'react';
import { Navigate } from 'react-router-dom';
import Login from './../pages/login/Login';
import Register from './../pages/register/Register';
import ForgotPassword from './../pages/forgotPassword/ForgotPassword';
import PrivateRoute from './../components/privateRoute/PrivateRoute';
import Home from '../pages/home/Home';
import EnterOtp from "../pages/enterOtp/EnterOtp";
import AboutUs from "../pages/aboutus/AboutUs";

// Unsecured routes
const unsecuredRoutes = [
    { path: "/login", element: <Login /> },
    { path: "/register", element: <Register /> },
    { path: "/forgot-password", element: <ForgotPassword /> },
    { path: "/verify-otp", element: <EnterOtp /> },
    { path: "/about-us", element: <AboutUs /> }
];

// Secured routes
const securedRoutes = [
    { path: "/", element: <PrivateRoute><Home /></PrivateRoute> },
    { path: "/my-account", element: <PrivateRoute><Home /></PrivateRoute> },
    // { path: "/about-us", element: <PrivateRoute><AboutUs /></PrivateRoute> },
    { path: "/alerts", element: <PrivateRoute><Home /></PrivateRoute> },
    { path: "/settings", element: <PrivateRoute><Home /></PrivateRoute> },
];

const routes = [
    ...unsecuredRoutes,
    ...securedRoutes,
    { path: "*", element: <Navigate to="/login" /> }
];

export default routes;