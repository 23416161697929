const API_BASE_URL = 'https://api.elitecloudfinserv.com/api/v1';

const API_ENDPOINTS = {
    AUTH:{
        LOGIN: `${API_BASE_URL}/auth/login`,
        REGISTER: `${API_BASE_URL}/auth/register`,
        FORGOT_PASSWORD: `${API_BASE_URL}/auth/forgot-password`,
        LOGOUT: `${API_BASE_URL}/auth/logout`,
    }
};

export default API_ENDPOINTS;
