import React, { useState, useEffect } from 'react';
import './Home.css';
import BottomNavigator from '../../components/bottomNavigator/BottomNavigator';

function Home() {
    const [isMobile, setIsMobile] = useState(false);

    const handleResize = () => {
        const mobile = window.innerWidth <= 768;
        setIsMobile(mobile);
    };

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className="home-container">
            <div className="content">
                <h1>Welcome to EliteCloud Finserve</h1>
                <p>Your dashboard and essential details will be here.</p>
                {/* Add more content here */}
            </div>
            {isMobile && <BottomNavigator />}
        </div>
    );
}

export default Home;
