import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Register.css';
import { apiPost } from '../../services/apiService';
import API_ENDPOINTS from '../../config/api';
import { MdPerson, MdEmail, MdPhoneAndroid, MdCalendarToday } from "react-icons/md";
import { RiLockPasswordFill } from "react-icons/ri";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import logo from '../../assets/images/logo.png';

function Register() {
    const [formData, setFormData] = useState({ name: '', dob: '', email: '', phone: '', password: '', confirmPassword: '' });
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
    const navigate = useNavigate();

    const { name, dob, email, phone, password, confirmPassword } = formData;

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleEyeToggle = (field) => {
        if (field === 'password') {
            setPasswordVisible(!passwordVisible);
        } else {
            setConfirmPasswordVisible(!confirmPasswordVisible);
        }
    };

    const handleRegister = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            console.error("Passwords do not match");
            return;
        }
        try {
            const registerData = { name, dob, email, phone, password };
            const response = await apiPost(API_ENDPOINTS.AUTH.REGISTER, registerData);
            console.log(response);
            navigate('/enter-otp');
        } catch (error) {
            console.error('Registration failed:', error);
        }
    };

    // Function to calculate the maximum allowed date for 18+ age restriction
    const getMaxDate = () => {
        const today = new Date();
        today.setFullYear(today.getFullYear() - 18);
        return today.toISOString().split('T')[0];
    };

    return (
        <div className={'main-container p-t-25'}>
            <form className="register-container">
                <img src={logo} alt="EliteCloud Finserve" className="logo" />
                <h2>Register with Us</h2>
                <p className="register-subtitle">Join EliteCloud Finserve and enjoy exclusive benefits!</p>

                <div className="input-group">
                    <MdPerson className="input-icon" />
                    <input
                        name="name"
                        value={name}
                        placeholder="Full Name"
                        onChange={handleChange}
                        className="input-field"
                    />
                </div>

                <div className="input-group">
                    <MdCalendarToday className="input-icon" />
                    <input
                        name="dob"
                        type="date"
                        value={dob}
                        onChange={handleChange}
                        className="input-field"
                        placeholder="Select DoB"
                        max={getMaxDate()} // Restrict date to 18+ years
                    />
                </div>

                <div className="input-group">
                    <MdEmail className="input-icon" />
                    <input
                        name="email"
                        value={email}
                        placeholder="Email"
                        onChange={handleChange}
                        className="input-field"
                    />
                </div>

                <div className="input-group">
                    <MdPhoneAndroid className="input-icon" />
                    <input
                        name="phone"
                        value={phone}
                        placeholder="+91 Phone Number"
                        onChange={handleChange}
                        className="input-field"
                    />
                </div>

                <div className="input-group">
                    <RiLockPasswordFill className="input-icon" />
                    <input
                        name="password"
                        value={password}
                        type={passwordVisible ? "text" : "password"}
                        placeholder="Password"
                        onChange={handleChange}
                        className="input-field"
                    />
                    <div className="eye-icon" onClick={() => handleEyeToggle('password')}>
                        {passwordVisible ? <IoMdEye /> : <IoMdEyeOff />}
                    </div>
                </div>

                <div className="input-group">
                    <RiLockPasswordFill className="input-icon" />
                    <input
                        name="confirmPassword"
                        value={confirmPassword}
                        type={confirmPasswordVisible ? "text" : "password"}
                        placeholder="Confirm Password"
                        onChange={handleChange}
                        className="input-field"
                    />
                    <div className="eye-icon" onClick={() => handleEyeToggle('confirmPassword')}>
                        {confirmPasswordVisible ? <IoMdEye /> : <IoMdEyeOff />}
                    </div>
                </div>

                <button className="register-button" onClick={handleRegister}>
                    Register
                </button>
            </form>
        </div>
    );
}

export default Register;
