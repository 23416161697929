import React from 'react';
import './BottomNavigator.css';
import { useNavigate } from 'react-router-dom';
import { MdHome, MdInfo, MdAccountCircle, MdNotifications, MdSettings } from "react-icons/md";

function BottomNavigator() {
  const navigate = useNavigate();

  return (
      <div className="bottom-navigation">
        <button className="nav-item" onClick={() => navigate('/')}>
          <MdHome/>
          <span>Dashboard</span>
        </button>
        <button className="nav-item" onClick={() => navigate('/my-account')}>
          <MdAccountCircle/>
          <span>Profile</span>
        </button>
        <button className="nav-item" onClick={() => navigate('/about-us')}>
          <MdInfo/>
          <span>About Us</span>
        </button>
        <button className="nav-item" onClick={() => navigate('/alerts')}>
          <MdNotifications/>
          <span>Alerts</span>
        </button>
        <button className="nav-item" onClick={() => navigate('/settings')}>
          <MdSettings/>
          <span>Settings</span>
        </button>
      </div>
  );
}

export default BottomNavigator;
