import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css';
import { apiPost } from '../../services/apiService';
import API_ENDPOINTS from '../../config/api';
import { MdEmail } from "react-icons/md";
import { RiLockPasswordFill } from "react-icons/ri";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import logo from '../../assets/images/logo.png';

function Login() {
    const [formData, setFormData] = useState({ username: '', password: '' });
    const [passwordVisible, setPasswordVisible] = useState(false);
    const navigate = useNavigate();

    const { username, password } = formData;

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleEyeToggle = () => {
        setPasswordVisible(!passwordVisible);
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const loginData = { username, password };
            const response = await apiPost(API_ENDPOINTS.AUTH.LOGIN, loginData);
            localStorage.setItem('token', response.token);
            navigate('/');
        } catch (error) {
            console.error('Login failed:', error);
        }
    };

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            apiPost(API_ENDPOINTS.AUTH.AUTO_LOGIN, { token })
                .then(response => {
                    if (response.isValid) {
                        navigate('/');
                    } else {
                        localStorage.removeItem('token');
                    }
                })
                .catch(error => console.error('Auto-login failed:', error));
        }
    }, [navigate]);

    return (
        <div className={'main-container p-t-25'}>
            <form className="login-container">
                <img src={logo} alt="EliteCloud Finserve" className="logo" />
                <h2>Welcome Back</h2>
                <p className="login-subtitle">Login to your account</p>

                <div className="input-group">
                    <MdEmail className="input-icon" />
                    <input
                        name="username"
                        value={username}
                        placeholder="Email or Phone"
                        onChange={handleChange}
                        className="input-field"
                    />
                </div>

                <div className="input-group">
                    <RiLockPasswordFill className="input-icon" />
                    <input
                        name="password"
                        value={password}
                        type={passwordVisible ? "text" : "password"}
                        placeholder="Password"
                        onChange={handleChange}
                        className="input-field"
                    />
                    <div className="eye-icon" onClick={handleEyeToggle}>
                        {passwordVisible ? <IoMdEye /> : <IoMdEyeOff />}
                    </div>
                </div>

                <button className="login-button" onClick={handleLogin}>
                    Log in
                </button>

                <div className="actions">
                    <a href="/forgot-password" className="forgot-password-link">Forgot Password?</a>
                    <a href="/register" className="create-account-link">Create Account</a>
                </div>
            </form>
        </div>
    );
}

export default Login;
