import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './EnterOtp.css';
import { apiPost } from '../../services/apiService';
import API_ENDPOINTS from '../../config/api';
import logo from '../../assets/images/logo.png';
import {MdSecurity} from "react-icons/md";

function EnterOtp() {
    const [otp, setOtp] = useState('');
    const navigate = useNavigate();

    const handleChange = (e) => {
        setOtp(e.target.value);
    };

    const handleVerifyOtp = async (e) => {
        e.preventDefault();
        try {
            const response = await apiPost(API_ENDPOINTS.AUTH.VERIFY_OTP, { otp });
            console.log(response);
            if (response.success) {
                navigate('/');
            } else {
                console.error('OTP verification failed');
            }
        } catch (error) {
            console.error('Failed to verify OTP:', error);
        }
    };

    return (
        <div className={'main-container p-t-25'}>
            <form className="otp-container">
                <img src={logo} alt="EliteCloud Finserve" className="logo" />
                <h2>Verify Your Email</h2>
                <p className="otp-subtitle">Enter the OTP sent to your email</p>

                <div className="input-group">
                    <MdSecurity className="input-icon" />
                    <input
                        name="otp"
                        value={otp}
                        placeholder="Enter OTP"
                        onChange={handleChange}
                        className="input-field"
                        maxLength={6}
                    />
                </div>

                <button className="otp-button" onClick={handleVerifyOtp}>
                    Verify OTP
                </button>
            </form>
        </div>
    );
}

export default EnterOtp;
